import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import { closeDialog } from '@/components/dialog';
import { env } from '@/env.mjs';
import { request } from '@/libs/request';

import { resetImpersonationForm } from '../model';

const appBaseUrlMap = {
  local: 'https://dev.app.paid.inc',
  dev: 'https://dev.app.paid.inc',
  sit: 'https://sit.app.paid.inc',
  uat: 'https://uat.app.paid.inc',
  prod: 'https://app.paid.inc',
};

const appBaseUrl = appBaseUrlMap[env.NEXT_PUBLIC_DEPLOY_ENV];

type AnswerImpersonationParams = {
  sessionToken: string;
  username: string;
  answer: string;
};

type AnswerImpersonationResponse = {
  data: {
    accessToken: string;
  };
} | null;

export const useAnswerImpersonation = () => {
  return useMutation({
    mutationKey: ['AnswerImpersonation'],
    mutationFn: async (params: AnswerImpersonationParams) => {
      const { data } = await request.post<AnswerImpersonationResponse>(
        '/answer-impersonation',
        params,
      );
      return data?.data ?? null;
    },
    onSuccess: (data) => {
      if (data) {
        window.open(`${appBaseUrl}?access_token=${data.accessToken}`, '_blank')?.focus();
      } else {
        toast.error('Failed to answer impersonation');
      }
    },
    onError: (error) => {
      toast.error(error instanceof Error ? error.message : 'Failed to answer impersonation');
    },
    onSettled: () => {
      resetImpersonationForm();
      closeDialog();
    },
  });
};
